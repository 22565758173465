.upload-items-menu {
  cursor: pointer;
  font-size: x-large;
  display: block;
  padding: 10px;
  color: #717273;
}

.upload-items-dropdown {
  position: absolute;
  background-color: #282b2d;
  border-radius: 4px;
  display: none;
  z-index: 100;
  bottom: 65%;
  left: 18%;
  /* padding: 10px 0 10px 0; */
}

.upload-items-menu:hover {
  color: #fff;
}

.upload-items-menu:hover .upload-items-dropdown {
  display: block;
}

.upload-items-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.upload-items-dropdown li {
  color: #717273;
  font-size: larger;
  padding: 10px 12px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.upload-items-dropdown li:hover {
  color: #fff;
  transform: scale(1.2);
}
