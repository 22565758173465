
.read-out-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #ffffff9b;
  }
  
  .message-bubble.reading .read-out-icon {
    color: #3ca0a0;
  }
  
  .speech-recognition-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: none;
    background-color: #2c3032;
    color: #a9a9a9;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .speech-recognition-button:hover {
    color: #118dff;
  }
  
  .speech-recognition-button:focus {
    outline: none;
  }
  
  .speech-recognition-button svg {
    margin-right: 10px;
    font-size:x-large
  }