.chart-container {    
  width: 100%;    
  max-width: 500px;    
  margin: 0 auto;    
  height: 450px;    
}    
  
.chart-canvas {    
  display: block;    
  width: 100%;    
  max-width: 500px;    
  height: 400px;    
  margin: 0 auto;    
}    

.chart-types{
  margin-left: 75%;
  background-color: transparent;
  color: #c1c1c1d2;
  border: none;
  text-align: right;
}

.chart-types option {
  background-color: #2c3032;
}

.chart-types option:hover {
 background-color: chocolate;
}

.chart-types:focus {
  outline: none;
}