* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --darktheme-dark-bg-color: #1f2122;
  --darktheme-medium-bg-color: #2c3032;
  --darktheme-medium-light-bg-color: #282b2d;
  --darktheme-light-bg-color: #34383b;

  --darktheme-light-text-color: #fff;
  --darktheme-medium-text-color: #c1c1c182;
  --darktheme-purple-color: #a100ff;

  
  --lighttheme-dark-bg-color: #d9d9d9;
  --lighttheme-medium-bg-color: #f2f2f2;
  --lighttheme-medium-user-bg-color: #f3f8ff;
  --lighttheme-light-bg-color: #fff;

  --lighttheme-dark-text-color: #222;
  --lighttheme-medium-text-color: #333;
  --lighttheme-light-text-color: #888;

  --lighttheme-blue-color: #118dff;
}
body {
  /* background-color: #2c3032; */
  background-color: var(--darktheme-dark-bg-color);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1px;
}
html, body, #root, .lighttheme{
  height: 100%;
}

.header {
  background-color: var(--darktheme-medium-bg-color);
  /* background-color: #1f2122; */
  color: var(--darktheme-purple-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.lighttheme .header {
  background-color: var(--lighttheme-medium-bg-color);
  box-shadow: 0px 2px 0px 0px #d8d8d8;
  color: var(--lighttheme-blue-color);
  /* z-index: 999; */
}

.header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.user-avatar {
  display: flex;
  align-items: center;
}

.user-avatar img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-avatar .user-initial {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: var(--darktheme-purple-color);
  color: #fff;
  font-size: 16px;
  /* font-weight: 600; */
  margin-right: 10px;
}
.lighttheme .user-avatar .user-initial {
  background-color: var(--lighttheme-blue-color);

}
.user-initial {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: var(--darktheme-purple-color);
  color: #fff;
  font-size: 16px;
  /* font-weight: 600; */
}
.lighttheme .user-initial {
  background-color: var(--lighttheme-blue-color);
}
.user-name {
  font-size: 1rem;
  /* font-weight: 600; */
  color: var(--darktheme-medium-text-color);
  /* /* padding-right: 10px; */
  /* width: 105px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  */
}
.lighttheme .user-name {
  color: var(--lighttheme-dark-text-color);
}

.user-name:hover {
  color: var(--darktheme-light-text-color);
}
.lighttheme .user-name:hover {
  color: var(--lighttheme-dark-text-color);
}

.lighttheme .user-role select{
  color: var(--lighttheme-medium-text-color);
}
.lighttheme .user-role select option {
  background-color: var(--lighttheme-medium-bg-color);
}
/* side nav */
.side-nav {
  position: fixed;
  top: 65px;
  width: 60px;
  height: 100%;
  background-color: var(--darktheme-medium-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 0.3s; /* Add a transition effect for smooth animation */
  z-index: 3;
  justify-content: space-between; 
}
.bottom-icon {
  /* padding: 10px; */
  margin-bottom: 80px; /* Adjust the space at the bottom as needed */
  color: #c1c1c182;
  cursor: pointer;
  font-size: x-large;
  text-align: center;
}
.lighttheme .side-nav {
  background-color: var(--lighttheme-medium-bg-color);
  box-shadow: 0px 0px 3px 0px #d8d8d8;
}

.side-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-nav li {
  padding: 10px;
  margin-top: 10%;
  color: var(--darktheme-medium-text-color);
  cursor: pointer;
  font-size: x-large;
  position: relative;
}
.lighttheme .side-nav li {
  color: var(--lighttheme-light-text-color);
}
.lighttheme .side-nav li:hover {
  color: var(--lighttheme-blue-color);
}

.side-nav li:hover {
  color: var(--darktheme-purple-color);
}

.side-nav li span {
  position: absolute;
  top: 50%;
  left: calc(100% + 10px); /* Position the text on the right side of the icon */
  transform: translateY(-50%);
  white-space: nowrap;
  opacity: 0; /* Hide the text by default */
  transition: opacity 0.3s; /* Add a transition effect for smooth opacity change */
  pointer-events: none; /* Prevent the text from blocking mouse events */
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #2c3032;
  color: #fff;
  font-size: 15px;
}
.side-nav li:hover span {
  opacity: 1; /* Show the text on hover */
}

.side-nav ul li.selected {
  color: var(--darktheme-purple-color);
}
.lighttheme .side-nav ul li.selected{
  color: var(--lighttheme-blue-color);
}
.chat-container {
  position: fixed;
  top: 52%;
  left: 51%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  height: 85%;
  width: 94%;
  background-color: var(--darktheme-medium-bg-color);
  color: #fff;
  overflow: hidden;
  z-index: 1;
  /* border-top: 0.3px solid #16719e; */
}
.lighttheme .chat-container {
  background-color: var(--lighttheme-light-bg-color);
}

/* chat nav */
.chat-nav {
  flex: 1;
  background-color: var(--darktheme-medium-light-bg-color);
  transition: width 0.3s ease-in-out; /* Add transition for smooth animation */
}
.lighttheme .chat-nav {
  background-color: var(--lighttheme-medium-bg-color);
}
.lighttheme .chat-history p{
  color: var(--lighttheme-dark-text-color);
}

.chat-nav.collapsed {
  flex: 0;
  overflow: hidden;
}

.chat-view {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.toggle-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease-in-out;
}
.lighttheme .toggle-button {
  color: var(--lighttheme-light-text-color);
}

.toggle-button:hover {
  color: var(--darktheme-purple-color);
}
.lighttheme .toggle-button:hover {
  color: var(--lighttheme-blue-color);
}

/* .toggle-button:focus {
  outline: none;
} */

/* .chat-nav.collapsed .toggle-button {
  color: #ffca28;
} */

.add-new-chat {
  background-color: var(--darktheme-light-bg-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  margin: 50px 10px 30px 10px;
  width: 90%;
}
.lighttheme .add-new-chat {
  background-color: var(--lighttheme-light-text-color);
}
.lighttheme .add-new-chat:hover {
  background-color: var(--lighttheme-blue-color);
}
.lighttheme .conv-hst-item {
  background-color: var(--lighttheme-light-text-color);
}
.lighttheme  .table-container thead tr th{
  color: var(--lighttheme-light-bg-color);
  background-color: var(--lighttheme-blue-color);
}
.table-container thead tr th{
  background-color: var(--darktheme-purple-color);
}
.add-new-chat:hover {
  background-color: var(--darktheme-purple-color);
}

.chat-history {
  font-size: 14px;
  overflow-y: auto;
  height: 80%; /* Adjust the height as needed */
}

.chat-history p {
  padding: 10px;
}

.conv-hst-item {
  background-color: var(--darktheme-light-bg-color);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.conv-hst-item span {
  margin-left: auto;
  color: #fff;
}

.conv-hst-item:hover {
  background-color: var(--darktheme-dark-bg-color);
}

.conv-hst-item span .pen {
   color: #a9a9a9;
  padding: 10px;
}

.conv-hst-item span .trash {
  color: #a9a9a9;
}

.conv-hst-item span .pen:hover {
  color: #279595;
  padding: 10px;
}

.conv-hst-item span .trash:hover {
  color: #d25858;
}

.conv-hst-item input,
button {
  background-color: transparent;
  border: none;
  color: #fff;
}

.conv-hst-item input[type="text"]:focus {
  outline: none;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-left: 5%;
  padding-bottom: 5%;
  padding-right: 5%;
  display: flex;
  overflow-x:hidden;
  flex-direction: column; /* Display the messages in reverse direction */
}

.message-bubble {
  background-color: #373b3c;
  margin-bottom: 20px;
  border-radius: 10px;
  max-width: 100%;
  word-wrap: break-word;
  padding: 20px;
  display: flex;
  align-items: center;
}

@keyframes slideInLeft {
  from {
    transform: translateX(100%); 
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%); 
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.message-bubble.user {
  align-self: flex-end; 
  background-color: var(--darktheme-light-bg-color);
  color: #fff;
  animation: slideInLeft 0.5s ease forwards;
}


.message-bubble.bot {
  align-self: flex-start; /* Align bot's message to the left */
  /* background-color: #272a2b; */
  background-color: var(--darktheme-light-bg-color);
  color: #fff;
  animation: slideInRight 0.5s ease forwards;
}
.lighttheme .message-bubble.bot {
  background-color: var(--lighttheme-medium-bg-color);
  color: var(--lighttheme-medium-text-color);
}
.lighttheme .message-bubble.user{
  background-color: var(--lighttheme-medium-user-bg-color);
  color: var(--lighttheme-medium-text-color);
}

.lighttheme .read-out-icon, .lighttheme .message-icon {
  color: var(--lighttheme-light-text-color);
}
.lighttheme .speech-recognition-button {
    background-color: var(--lighttheme-light-bg-color);
    color: var(--lighttheme-light-text-color)!important;
}
.message-text {
  line-height: 1.5;
  margin-left: 10px;
  white-space: pre-wrap;
  font-size: 16px;
}

.message-icon {
  font-size: 1.2rem;
  margin-right: 10px;
  color: var(--darktheme-medium-text-color);
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  position: relative;
  max-width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  background-color: transparent;
}

.chat-input input[type="text"] {
  flex: 1;
  background-color: #40414f;
  color: #fff;
  border: none;
  padding: 30px;
  font-size: 1rem;
  border-radius: 20px;
}

.chat-input input[type="text"]:focus {
  outline: none;
}

.send-button {
  background-color: #529ecd;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  margin-left: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #279595;
}

.chat-input textarea {
  flex: 1;
  background-color: var(--darktheme-dark-bg-color);
  color: var(--darktheme-light-text-color);
  padding: 20px;
  font-size: 1rem;
  border-radius: 15px;
  resize: none;
  height: 60px;
  overflow: hidden; /* Hide the scrollbar */
  padding-right: 60px;
  border: none;
}
.lighttheme .chat-input textarea {
  background-color: var(--lighttheme-dark-bg-color);
  color: var(--lighttheme-dark-text-color);
}

.chat-input textarea:focus {
  outline: none;
}

/* scrollbar */
/* Set the style of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #40414f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #747272;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Reset button */
.reset-button {
  background-color: #2c3032;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  bottom: 10;
  right: 6%;
  margin: 10px;
}

.reset-button:hover {
  background-color: #40414f;
}

.suggestion-bubble {
  background-color: var(--darktheme-light-bg-color);
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
}

.lighttheme .suggestion-bubble {
  background-color: var(--lighttheme-dark-bg-color);
  color: var(--lighttheme-medium-text-color);
}

.suggestion-bubble:hover {
  background-color: var(--darktheme-dark-bg-color);
}
.lighttheme .suggestion-bubble:hover {
  background-color: var(--lighttheme-medium-bg-color);
}
.input-suggestions {
  text-align: center;
  padding-top: 15px;
}

.send-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  color: var(--darktheme-medium-text-color);
  border-radius: 5px;
  font-size: 40px;
  cursor: pointer;
  margin-left: -30px;
}
.speech-recognition-button {
  color: var(--darktheme-medium-text-color)!important;
}
.lighttheme .send-icon {
  color: var(--lighttheme-light-text-color);
}
.lighttheme .send-icon:hover {
  color: var(--lighttheme-blue-color);
}
.lighttheme{
  width: 100%;
  height: 100%;
  background-color: var(--lighttheme-dark-bg-color);
}
.send-icon:hover {
  color: var(--darktheme-purple-color);
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.loader {
  margin-left: -20px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid rgba(77, 176, 176, 0.2);
  border-left-color: #4db0b0;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.user-role {
  text-align: end;
}
.user-role select {
  background-color: transparent;
  border: none;
  color: var(--darktheme-medium-text-color);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.user-role select option {
  background-color: #2c3032;
}

.user-role select:focus {
  outline: none;
}


.loader2 {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
}  
  
.loader2 > div {  
  width: 10px;  
  height: 10px;  
  background-color: var(--darktheme-purple-color);  
  border-radius: 50%;  
  margin: 0 2px;  
  animation: bounce 1.4s ease-in-out infinite;  
}  
.lighttheme .loader2 > div {
  background-color: var(--lighttheme-blue-color); 
}
  
.loader2 .dot1 {  
  animation-delay: -0.32s;  
}  
  
.loader2 .dot2 {  
  animation-delay: -0.16s;  
}  
  
@keyframes bounce {  
  0%,  
  80%,  
  100% {  
    transform: translateY(0);  
  }  
  40% {  
    transform: translateY(-15px);  
  }  
}  

.conv-hst-item.active {  
  background-color: var(--darktheme-purple-color);
  /* font-weight: bold;   */
  /* Add any other styles you want for the active conversation */  
  /* border: .5px solid red; */
}  
.lighttheme .conv-hst-item.active {  
  background-color: var(--lighttheme-blue-color);
}

/*toggle css starts */
#checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111;
  width: 60px;
  height: 29px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dark-text {color: #fff; font-size: 9px;}

.light-text {color: #fff; font-size: 9px;}

.checkbox-label .ball {
  background-color: #fff;
  width: 26px;
  height: 26px;
  position: absolute;
  left: 0px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.1s linear;
}

#checkbox:checked + .checkbox-label .ball {
  transform: translateX(34px);
}
.toggle-container{
    /* position: fixed;
    right: 14%;
    z-index: 999999;
    top:3%; */
}

/*toggle css ends */

/* insight page css starts */
.lighttheme .insights {
  background-color: var(--lighttheme-dark-bg-color);
}
.insights h2{
  color: var(--darktheme-purple-color);
}
.lighttheme .insights h2{
  color: var(--lighttheme-blue-color);
}
.insights-grey-box {
  background-color: var(--darktheme-light-bg-color); /* White background color */
}
.lighttheme .insights-grey-box {
  background-color: var(--lighttheme-medium-bg-color); /* White background color */
}
.insights-car-card {  
  background-color: var(--darktheme-medium-bg-color); 
}
.lighttheme .insights-car-card {  
  background-color: var(--lighttheme-light-bg-color); 
}
.insights-car-title { 
  color: var(--darktheme-purple-color);
}
.lighttheme .insights-car-title { 
  color: var(--lighttheme-blue-color);
}
.insights-car-description { 
  color: var(--darktheme-light-text-color);
}
.lighttheme .insights-car-description { 
  color: var(--lighttheme-dark-text-color);
}
/* insight page css ends */

/* new page css starts */
.lighttheme .news {
  background-color: var(--lighttheme-dark-bg-color);
}
.news h2{
  color: var(--darktheme-purple-color);
}
.lighttheme .news h2{
  color: var(--lighttheme-blue-color);
}
.news-grey-box {
  background-color: var(--darktheme-light-bg-color); /* White background color */
}
.lighttheme .news-grey-box {
  background-color: var(--lighttheme-medium-bg-color); /* White background color */
}
.news-car-title { 
  color: var(--darktheme-purple-color);
}
.lighttheme .news-car-title { 
  color: var(--lighttheme-blue-color);
}
.news-car-card {  
  background-color: var(--darktheme-medium-bg-color);  
}
.lighttheme .news-car-card {  
  background-color: var(--lighttheme-light-bg-color); 
}
.news-car-description { 
  color: var(--darktheme-light-text-color);
}
.lighttheme .news-car-description { 
  color: var(--lighttheme-dark-text-color);
}
/* new page css ends */
/* inteligence page css starts */
.lighttheme .optimize {
  background-color: var(--lighttheme-dark-bg-color);
}
.optimize h2{
  color: var(--darktheme-purple-color);
}
.lighttheme .optimize h2{
  color: var(--lighttheme-blue-color);
}
.optimize-grey-box {
  background-color: var(--darktheme-light-bg-color); /* White background color */
}
.lighttheme .optimize-grey-box {
  background-color: var(--lighttheme-medium-bg-color); /* White background color */
}
.optimize-car-card {  
  background-color: var(--darktheme-medium-bg-color); 
}
.lighttheme .optimize-car-card {  
  background-color: var(--lighttheme-light-bg-color); 
}
.optimize-car-title {  
  color: var(--darktheme-purple-color);
}
.lighttheme .optimize-car-title {  
  color: var(--lighttheme-blue-color);
}
.optimize-car-description { 
  color: var(--darktheme-light-text-color);
}
.lighttheme .optimize-car-description { 
  color: var(--lighttheme-dark-text-color);
}
/* inteligence page css ends */