.news {  
  /* width: 100%;  
  max-width: 1700px;   */
  margin: 0 auto;  
  padding: 30px 30px 30px 85px;
  /* margin-top:80px; */
  /* margin-left: 125px; */
}  
.news-grey-box {
  padding: 20px 20px 20px 30px;
  border-radius: 1px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}   

.news-car-grid {  
  display: grid;  
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  
  grid-gap: 70px; /* Adjust this value to change the distance between cards */  
  justify-content: center; /* Add this line to center the grid items */  
}  
.lighttheme .news {
  /* background-color: #d9d9d9; */
}
.lighttheme .news-car-card {
  /* background-color: #fff; */
}
.lighttheme .news-car-card p.news-car-description{
  /* color: #222; */
}
.news-car-card {  
  /* background-color: #2c3032;   */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
  border-radius: 6px;  
  overflow: hidden;  
  cursor: pointer;  
  transition: all 0.3s ease;  
  width: auto; /* Add this line to set a fixed width for the cards */  
} 

.news-car-card:hover {  
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);  
  transform: translateY(-5px);  
}  
  
.news-car-image {  
  width: 100%;  
  height: 200px;  
  object-fit: fill;  
}  
  
.news-car-title {  
  font-size: 20px;  
  font-weight: bold;  
  margin: 16px;  
  /* color: #118dff; */
}  
  
.news-car-description {  
  font-size: 14px;  
  margin: 0 16px 16px;  
  /* color: #fff; */
}  

.news h2{
/* color: #118dff; */
font-size: 40px;
padding: 60px 10px 40px 10px;
;
}