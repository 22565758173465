/* Styles for the modal */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282b2d;
  color: #dfdfdf;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  z-index: 1000;
  font-size: 16px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal button {
  color: #f26f6b;
  font-size: 20px;
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

.setting-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.setting-option label {
  margin-right: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 79, 79, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.dropdown-select {
  display: inline-block;
  position: relative;
}

.dropdown-select select {
  appearance: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 8px 40px;
  color: #ffff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  transition: all 0.3s ease; 
}

.dropdown-select select:hover {
  background-color: #282b2d;
  border-color: #b1b1b1; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.dropdown-select select:focus {
  outline: none;
  background-color: #282b2d;
}

.dropdown-select:after {
  content: "\25BC";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #777;
  pointer-events: none;
}
