.message-bubble:hover .message-options-menu {
  display: block;
}

.message-bubble .message-options-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: small;
  display: none;
}

.message-options-dropdown {
  position: absolute;
  background-color: #282b2d;
  border-radius: 4px;
  display: none;
  z-index: 100;
  bottom: 15px;
}

.message-options-menu:hover {
  opacity: 1;
}

.message-options-menu:hover .message-options-dropdown {
  display: block;
}

.message-options-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.message-options-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.message-options-dropdown li:hover {
  transform: scale(1.3);
}
