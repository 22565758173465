.table-container {
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
}
.table-container tr:hover {
  background-color: #f5f5f517;
  cursor: pointer;
}
.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 8px;
  text-align: left;
  font-weight: lighter;
}

.table-container th {
  background-color: #134f4f;
  font-weight: 400;
}

.table-container th,
.table-container td,
.table-container tr {
  border: 1px solid #ddd;
}

.table-download {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: transparent;
  border: none;
}
